import React, { useState, useEffect } from "react";
import "./AllAboutLove.css";
import Typewriter from 'typewriter-effect';

function AllAboutLove() {
    const [showTypewriter, setShowTypewriter] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
            setShowTypewriter(true);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    const TypewriterComponent = () => {
        return (
          <div className="typewriter-container">
            <Typewriter
              options={{
                autoStart: true,
                loop: false,
                delay: 75,
              }}
              onInit={(typewriter) => {
                typewriter
                  .typeString("It's all about LAKE")
                  .pauseFor(1000)
                  .deleteChars(3) // Deletes "lake"
                  .typeString('<span style="color:red;">O</span>VE')
                  .start();
              }}
            />
          </div>
        );
      };

    return(
        <section className="all-about-love">
            <div className="easter-egg" id='all-about-love-section'>
                {showTypewriter && <TypewriterComponent />}
            </div>
        </section>
    )
}

export default AllAboutLove;