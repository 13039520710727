import React, { useEffect, useState } from 'react';
import './About.css';
import yakImage from '../assets/yak-is-king.png'

function About() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const targetSection = document.getElementById('about-section');
      const rect = targetSection.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top <= windowHeight * 0.5) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className='about' id='about-section'>
      <div className={`about-image-container ${isVisible ? 'visible' : ''}`}>
        <img src={yakImage} alt="Yak" className="yak-image" />
      </div>
      <div className={`about-text-container ${isVisible ? 'visible' : ''}`}>
        <h2>ABOUT</h2>
        <p>A battle for the title of "King of Memes" is unfolding globally, with individuals using wit and creativity in the virtual realm to claim victory. 
          Yet, this is not a conflict known to Yak, a legendary ruler from ancient times with unmatched power and wisdom. 
          Observing the frivolous pursuit of the meme crown, Yak aimed to instill the true essence of kingship—rooted in honor and duty rather than mere victories.</p>

        <p>Yak engaged in the game with ease, effortlessly winning time and again before quietly withdrawing.
           His presence loomed like a deep shadow over the meme world, embarrassing the self-proclaimed champions with his casual victories. 
           For Yak, this was a lesson in true leadership: victory was easy to achieve, but the genuine struggle worth pursuing was for the love of one’s kin.</p>

        <p>Ultimately, Yak reclaimed his throne. From his vantage, he offered a subtle warning to those craving victory and power: victories are illusory; the truest strength lies in love, nurtured by inner peace and fidelity.</p>
      </div>
    </section>
  );
}

export default About;
