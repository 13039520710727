import React from 'react';
import './ExternalLinks.css';

function ExternalLinks() {
    return(
        <div id='ExternalLinks'>
            <span className='flex-container'>
                <a className='icons' href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                    <img src={require('../assets/twitter-icon.png')} alt='Link1'/>
                </a>
                <a className='icons'href="https://telegram.com" target="_blank" rel="noopener noreferrer">
                    <img src={require('../assets/frog-icon.png')} alt='Link2'/>
                </a>
                <a className='icons'href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                    <img src={require('../assets/m-icon.png')} alt='Link3'/>
                </a>
                <a className='icons'href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                    <img src={require('../assets/ghost-icon.png')} alt='Link4'/>
                </a>
                <a className='icons'href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                    <img src={require('../assets/dex-icon.png')} alt='Link5'/>
                </a>
                <img className='small-yak'src={require('../assets/readjusted-yak.png')}alt="Yak Face"></img>
            </span>
        </div>
    )
}

export default ExternalLinks