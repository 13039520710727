import React, {useEffect, useState} from 'react';
import './UniSwap.css';
import splitLine from '../assets/split-line.png'
import pepe from '../assets/pepe-running.png'

function UniSwap() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
        const targetSection = document.getElementById('uniswap-section');
        const rect = targetSection.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (rect.top <= windowHeight * 0.5) {
            setIsVisible(true);
        }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <section className='uniswap-section'>
            <div className='address-container'>
                <p className='address-text'>Address:5LafQUrVco6o7KMz42eqVEJ9LW31StPyGjeeu5sKoMtA</p>
                <img className='split-line' src={splitLine} alt="Split"/>
            </div>

            <div className='uniswap-main-container' id='uniswap-section'>
                <div className='left-container'>
                    <iframe className='uniswap-container' title="uniswap-interface" src="https://app.uniswap.org/#/swap?outputCurrency=0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359"/>
                    <p className='trade-text'>Trade on Solona</p>
                </div>

                <div className='right-container'>
                    <img className={`pepe-img ${isVisible ? 'visible' : ''}`} src={pepe} alt="Pepe"/>
                </div>

            </div>
        </section>
    )
}

export default UniSwap