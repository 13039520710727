import React, {useEffect, useState} from 'react';
import './HowToBuy.css';
import yakImg from '../assets/how-to-buy.jpg';
import yakKing from '../assets/yak-king.jpg'
import stepIcon from '../assets/step-icon.png';
import lyingMew from '../assets/lying-mew.png'

function HowToBuy() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const targetSection = document.getElementById('how-to-buy-section');
      const rect = targetSection.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top <= windowHeight * 0.5) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="how-to-buy">
      <div className='main-image-container'>
        <img className='yak-img' src={yakImg} alt="Yak"/>
      </div>
      <div className='buy-main-container' id='how-to-buy-section'>

        <div className='steps-text-container'>
          <h2>How To Buy</h2>
          <div className='steps-container'>
            <span className={`step-num ${isVisible ? 'visible' : ''}`}>
              <img className='step-icon' src={stepIcon} alt="Step Icon"/>
              <h3> Step1</h3>
            </span>
            <p className={`step-text ${isVisible ? 'visible' : ''}`}>Create a Solana Wallet for example use Phantom & load some SOL, if you don’t have any SOL, you can buy directly on phantom, transfer from another wallet, or buy on another exchange and send it to your wallet.</p>

            <span className={`step-num ${isVisible ? 'visible' : ''}`}>
              <img className='step-icon' src={stepIcon} alt="Step Icon"/>
              <h3> Step2</h3>
            </span>
            <p className={`step-text ${isVisible ? 'visible' : ''}`}>Go to <a href='https://raydium.io/swap/'>https://raydium.io/swap/</a> & connectyour wallet. You can find <span className="highlight-blue">$YAKK</span> as an option by pasting in the contract address below:</p>
            <span className="highlight-blue"><p className={`step-text ${isVisible ? 'visible' : ''}`}>5LafQUrVco6o7KMz42eqVEJ9LW31StPyGjeeu5sKoMtA</p></span>

            <span className={`step-num ${isVisible ? 'visible' : ''}`}>
              <img className='step-icon' src={stepIcon} alt="Step Icon"/>
              <h3> Step3</h3>
            </span>
            <p className={`step-text ${isVisible ? 'visible' : ''}`}>Switch SOL for $YAKK & make yourself a millionaire !</p>
          </div>
          <div>
            <img className={`lying-mew ${isVisible ? 'visible' : ''}`} src={lyingMew} alt="Lying Mew"/>
          </div>
        </div>
        <div className='side-image-container'>
          <img className='yak-napoleon' src={yakKing} alt="Yak Napoleon"></img>
        </div>
      </div>

    </section>
  );
}

export default HowToBuy;
