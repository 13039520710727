import React, { useEffect, useState } from 'react';
import './Roadmap.css';
import yakToy from '../assets/yak-toy.jpg'
import leftImg from '../assets/poping-doge.png'
import rightImg from '../assets/trash.png'
import checkmarkIcon from '../assets/checkmark.png'
import undoneIcon from '../assets/undone-icon.png'

function Roadmap() {
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      const phaseContainer = document.getElementById('phase-container-section');
      const rect = phaseContainer.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top <= windowHeight * 0.75) {
        setIsVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="roadmap">
      <div className='image-container'>
        <img className='yak-toy' src={yakToy} alt="Yak Toy"/>
      </div>
      <div className='roadmap-main-container'>
        <div className='roadmap-left-img-container'>
          <img className='left-img' src={leftImg} alt="Left Img"/>
        </div>

        <div className='roadmap-text-container'>
          <h2>ROAD MAP</h2>

          <div className='phase-container' id='phase-container-section'>
            <div className={`phase-launch-card ${isVisible ? 'visible' : ''}`}>
              <p>PHASE 1 LAUNCH</p>
              <ul className="phase-tasks">
                <li className="task-item" style={{ transitionDelay: '0.3s' }}>
                  <img src={checkmarkIcon} alt="Checkmark" className="checkmark-icon" />
                  Develop a smart contract
                </li>
                <li className="task-item" style={{ transitionDelay: '0.6s' }}>
                  <img src={checkmarkIcon} alt="Checkmark" className="checkmark-icon" />
                  Official Website creation
                </li>
                <li className="task-item" style={{ transitionDelay: '0.9s' }}>
                  <img src={checkmarkIcon} alt="Checkmark" className="checkmark-icon" />
                  Initial Marketing & Community building
                </li>
              </ul>
            </div>
          </div>

          <div className='phase-container'>
            <div className={`phase-launch-card ${isVisible ? 'visible' : ''}`} style={{ transitionDelay: '1.2s' }}>
              <p>PHASE 2 GROWTH</p>
              <ul className="phase-tasks">
                <li className="task-item" style={{ transitionDelay: '1.5s' }}>
                  <img src={checkmarkIcon} alt="Checkmark" className="checkmark-icon" />
                  Exchange Listing
                </li>
                <li className="task-item" style={{ transitionDelay: '1.8s' }}>
                  <img src={checkmarkIcon} alt="Checkmark" className="checkmark-icon" />
                  Expand the Communities
                </li>
                <li className="task-item" style={{ transitionDelay: '2.1s' }}>
                  <img src={checkmarkIcon} alt="Checkmark" className="checkmark-icon" />
                  Partnerships
                </li>
              </ul>
            </div>
          </div>

          <div className='phase-container'>
            <div className={`phase-launch-card ${isVisible ? 'visible' : ''}`} style={{ transitionDelay: '2.4s' }}>
              <p>PHASE 3 True Value</p>
              <ul className="phase-tasks">
                <li className="task-item" style={{ transitionDelay: '2.7s' }}>
                  <img src={undoneIcon} alt="Undone" className="undone-icon" />
                  Establishing a DAO
                </li>
                <li className="task-item" style={{ transitionDelay: '3.0s' }}>
                  <img src={undoneIcon} alt="Undone" className="undone-icon" />
                  Real world value connection
                </li>
                <li className="task-item" style={{ transitionDelay: '3.3s' }}>
                  <img src={undoneIcon} alt="Undone" className="undone-icon" />
                  Human Next-gen Responsibility Ecosystem
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='roadmap-right-img-container'>
          <img className='right-img' src={rightImg} alt="Right Img"/>
        </div>

      </div>
    </section>
  );
}

export default Roadmap;
