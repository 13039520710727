import React from 'react';
import './Tokenomics.css';
import wallStreetYak from '../assets/wall-street-yak.jpg'
import blueDot from '../assets/dot.png'
import yakKing from '../assets/yak-is-king-no-text.png'

function Tokenomics() {
  return (
    <section className="tokenomics">
      <div className='image-container'>
        <img className='wallStreetYak'src={wallStreetYak} alt="Wallstreet Yak"/>
      </div>

      <div className='tokenomics-main-container'>
        <div className='tokenomics-text-container'>
          <h2 className='tokenomics-font'>TOKENOMICS</h2>

          <div className="supply-container">
            <div className='supply-number-container'>
              <img src={blueDot} alt="Blue Dot" className="supply-blue-dot" />
              <span className="supply-number">1,688,800,000,000,000</span>
            </div>

              <span className="supply-text">Total Supply</span>
          </div>

          <div className="distribution">
            <div className="distribution-item">
              <img src={blueDot} alt="Blue Dot" className="blue-dot" />
              <span className="percentage">70%</span>
              <span className="description">LP</span>
            </div>
            <div className="distribution-item">
              <img src={blueDot} alt="Blue Dot" className="blue-dot" />
              <span className="percentage">20%</span>
              <span className="description">Airdrops</span>
            </div>
            <div className="distribution-item">
              <img src={blueDot} alt="Blue Dot" className="blue-dot" />
              <span className="percentage">10%</span>
              <span className="description">TEAM</span>
            </div>
          </div>
        </div>
        <div className='side-img-container'>
          <img className='yak-king' src={yakKing} alt="Yak King"/>
        </div>
      </div>
    </section>
  );
}

export default Tokenomics;
