import React from 'react'
import './SideBar.css'
import sidebar from '../assets/sidebar.png'

function SideBar() {
    return(
        <div>
            <img className='sidebar' src={sidebar} alt="Sidebar"/>
        </div>
    )
}

export default SideBar