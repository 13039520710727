import React, {useEffect, useState} from 'react';
import './FindUs.css';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import goDownArrow from '../assets/go-down.svg'

function FindUs() {
  const [reachBot, setReachBot] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      const targetSection = document.getElementById('more-content-section');
      const rect = targetSection.getBoundingClientRect();
      const startY = rect.top + window.scrollY - 480; // 50px before the target section
      const endY = startY + 200; // 50px into the target section
      const scrollY = window.scrollY;

      
      // if (window.scrollY > startY) {
      //   if (!reachBot) {
      //     event.preventDefault();
      //     event.setDeltaY = 0;
      //     window.scrollTo(0, startY);
      //   }

      //   setReachBot(true);
      // }

      // Apply hard scroll within the specified range
      if (scrollY >= startY && scrollY < endY) {
        event.preventDefault();
        const deltaY = event.deltaY;
        console.log(deltaY);

        // Increase the resistance by reducing the scroll speed
        const resistanceFactor = 10; // Adjust this value to increase/decrease resistance

        // Calculate the new scroll position
        const newScrollY = scrollY + deltaY * resistanceFactor;
        console.log(newScrollY);

        // Scroll to the new position
        window.scrollTo({
          top: newScrollY,
          behavior: 'smooth', // Use 'auto' to avoid smooth scrolling interfering with resistance
        });
      }
    };

    window.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [reachBot]);


  const handleButtonClick = () => {
    document.getElementById('all-about-love-section').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <section className="find-us">
      <h2>Find Us</h2>
      <div className='twitter-embed'>
        <TwitterTweetEmbed className='twitter-content' tweetId={'463440424141459456'} />
      </div>
      <div className='more-content-parent' id='more-content-section'>
        <div className='more-content'>
          <p className='more-text'>More</p>
          <p className='more-text'>More</p>
          <p className='more-text'>More...</p>
          <img onClick={handleButtonClick} className='go-down-arrow' src={goDownArrow} alt="Go Down Arrow"/>
        </div>
      </div>
    </section>
  );
}

export default FindUs;
