import React from 'react';
import Header from './components/Header';
import About from './components/About';
import HowToBuy from './components/HowToBuy';
import Tokenomics from './components/Tokenomics';
import Roadmap from './components/Roadmap';
import FindUs from './components/FindUs';
import FixedBar from './components/FixedBar';
import ExternalLinks from './components/ExternalLinks'
import UniSwap from './components/UniSwap';
import SideBar from './components/SideBar';
import AllAboutLove from './components/AllAboutLove';
import useDeviceDetect from './components/DetectUserDevice';
import './App.css';

function App() {
  const isMobile = useDeviceDetect();

  return (
    isMobile? 
    <div className="App">
      <Header />
      <ExternalLinks />
      <About />
      <HowToBuy />
      <UniSwap />
      <Tokenomics />
      <Roadmap />
      <FindUs />
      <AllAboutLove />
    </div> :
    <div className="App">
      <FixedBar />
      <SideBar />
      <Header />
      <ExternalLinks />
      <About />
      <HowToBuy />
      <UniSwap />
      <Tokenomics />
      <Roadmap />
      <FindUs />
      <AllAboutLove />
    </div>
  );
}

export default App;
