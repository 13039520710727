import React from 'react';
import './Header.css';
import Typewriter from 'typewriter-effect';

function Header() {
  return (
    <header className="header">
      <div className="header-content">
        <div className="yak-is-back-container">
          <Typewriter
            options={{
              autoStart: true,
              loop: true,
              delay: 75,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString("YAK IS BACK !")
              .pauseFor(3000)
              .start();
          }}
          />
        </div>
        <p className='sub-title'>You can't stop him, cuz no one could!</p>
      </div>
    </header>
  );
}

export default Header;
