import React from 'react';
import './FixedBar.css';
import yakIcon from '../assets/barIcon.png'; // Make sure to replace with the correct path to your icon

function FixedBar() {
  const handleButtonClick = () => {
    document.getElementById('how-to-buy-section').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="fixed-bar">
      <img src={yakIcon} alt="Yak Icon" className="icon" />
      <div className="text">
        <marquee behavior="scroll" direction="right">YAK THE KING !!</marquee>
      </div>
      <span className="currency">$YAKK</span>
      <button className="buy-button" onClick={handleButtonClick}>BUY</button>
    </div>
  );
}

export default FixedBar;
